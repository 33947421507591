* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 300;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  display: none;
}
code {
  font-family: "Cutive Mono", monospace;
}
$primary: #1976d2;
@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}
#root {
  .App {
    width: 100vw;
    min-height: 100vh;
    background-color: black;
    .scroll-prompt {
      &.hidden {
        opacity: 0;
      }

      position: fixed;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      animation: fadeInOut 2s infinite;
      z-index: 1000;
    }
    .mouse {
      font-size: 40px;
    }
    .scroll-arrow {
      animation: bounceScroll 1.5s infinite, delayShow 3s;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 700;
    }
    @keyframes bounceScroll {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(10px);
      }
      60% {
        transform: translateY(5px);
      }
    }

    @keyframes fadeInOut {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
    .container {
      overflow: auto;
      width: 100%;
      @media screen and (max-width: 425px) {
        overflow-x: hidden;
      }
      & > * {
        padding: 0 200px;
        @media screen and (max-width: 1440px) {
          padding: 0 150px;
        }
        @media screen and (max-width: 1024px) {
          padding: 0 10px;
        }
      }
      .Home {
        @include center();
        height: 100vh;
        width: 100%;
        position: relative;
        overflow: hidden;
        .mobile {
          visibility: hidden;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: transparent;
          @include center();
          align-items: flex-end;
          justify-content: flex-end;
          color: white;
          z-index: 999;
          p {
            opacity: 0;
            animation: bounce 2s ease both infinite, show 1s;
            animation-delay: 6s;
            animation-fill-mode: forwards;
          }
        }
        .welcome {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: black;
          z-index: 999;
          @include center();
          font-weight: 700;
          font-size: 6em;
          color: white;
          animation: fadeAway 3s;
          animation-fill-mode: forwards;
          span {
            color: $primary;
            font-weight: inherit;
            font-size: inherit;
          }
        }
        .imageContainer {
          animation: moveUp 2s ease both;
          animation-delay: 2.5s;
          width: 50%;
          height: 100%;
          position: relative;
          z-index: 99;
          @include center();
          .blob {
            width: 100%;
            //animation: rotate 6s infinite;
          }
          .photo {
            position: absolute;
            width: 250px;
            height: 250px;
            border-radius: 50%;
            object-fit: cover;

            @media screen and (max-width: 1440px) {
              width: 150px;
              height: 150px;
            }
            @media screen and (max-width: 1024px) {
              width: 120px;
              height: 120px;
            }
          }
        }
        .textContainer {
          animation: moveLeft 2s ease both;
          animation-delay: 2.5s;
          width: 50%;
          height: 100%;
          padding: 70px 0;
          .about-link:hover span:after {
            content: "About";
          }
          .work-link:hover span:after {
            content: "Work";
          }
          .contact-link:hover span:after {
            content: "Contact";
          }
          span {
            font-weight: 700;
            font-size: 5em;
            cursor: pointer;
            user-select: none;
            transition: all 0.3s;
            &:hover {
              margin-left: 30px;
            }
          }
          .about-link span:after {
            color: white;
            content: "Hello.";
          }
          .work-link span:after {
            color: $primary;
            content: "I am";
          }
          .contact-link span:after {
            color: $primary;
            content: "Med";
          }
        }
        .react-icon {
          position: absolute;
          width: 1000px;
          height: 1000px;
          bottom: -300px;
          right: -300px;
          animation: spin infinite 20s linear, fadeIn 1.5s ease-in-out;
          opacity: 0.3;
        }
      }
      .Error {
        @include center();
        color: white;
        height: 100vh;
        width: 100%;
        flex-direction: column;
        h1 {
          font-size: 4em;
          font-weight: 700;
          width: 100%;
          text-align: center;
        }
        img {
          width: 100px;
          height: 100px;
          animation: wave 1s infinite;
        }
      }
      .Work,
      .About,
      .Contact {
        color: white;
      }
      .About {
        overflow: scroll;
        height: 250vh;
        position: relative;
        header {
          animation: aboutHeader 1s ease both;
          width: 100%;
          height: 100vh;
          @include center();
          p {
            font-weight: 700;
            font-size: 4em;
            user-select: none;
            i {
              font-size: 0.8em;
              color: $primary;
            }
          }
        }
        section {
          width: 100%;
          height: 150vh;
          padding: 0 150px;
          h3 {
            font-weight: 500;
            font-size: 2.5em;
          }
          p {
            font-weight: 300;
            font-size: 1.5em;
            code {
              font-weight: 100;
              font-size: 1em;
              color: rgba(19, 226, 19, 0.685);
            }
            a {
              text-decoration: none;
              font-size: inherit;
              font-weight: inherit;
              color: $primary;
              transition: all 0.3s;
              &:hover {
                margin-left: 20px;
              }
            }
          }
        }
      }
      .Work {
        width: 100%;
        padding: 100px 0;
        section {
          //height: 100vh;
          padding: 50px 0;
          position: relative;
          animation: moveLeftWork 1s ease both;
          .link {
            a {
              text-decoration: none;
              font-size: 1.5em;
              font-weight: 100;
              color: white;
              transition: all 0.4s;
              &:hover {
                color: $primary;
              }
            }
            animation: link 3s ease both infinite;
            position: absolute;
            bottom: 20px;
            right: 0;
            i {
              font-size: inherit;
            }
          }
          .name {
            height: 30%;
            @include center();
            justify-content: flex-start;
            margin-bottom: 50px;
            h2 {
              font-weight: 700;
              font-size: 4em;
              @media screen and (max-width: 1440px) {
                font-size: 3em;
              }
              @media screen and (max-width: 1024px) {
                font-size: 1.5em;
              }
            }
          }
          .showcase {
            height: 70%;
            width: 100%;
            @include center();
            .image {
              width: 50%;
              height: 100%;
              @media screen and (max-width: 1024px) {
                padding: 0 20px;
              }
              @include center();
              .imageWrapper {
                border: 2px solid #62757f;
                border-top: 15px solid #62757f;
                border-radius: 5px;
                height: 230px;
                width: 470px;
                position: relative;
                @media screen and (max-width: 1440px) {
                  width: 400px;
                  height: 200px;
                }
                @media screen and (max-width: 1024px) {
                  width: 100%;
                  height: 180px;
                }
                img {
                  width: 100%;
                  object-fit: cover;
                  height: 100%;
                }
                .dots {
                  position: absolute;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 2.5px;
                  top: -15px;
                  left: 5px;
                  div {
                    width: 10px;
                    height: 10px;
                    margin: 0 2px;
                    border-radius: 50%;
                    &:nth-child(1) {
                      background-color: #ff1744;
                    }
                    &:nth-child(2) {
                      background-color: #ffff00;
                    }
                    &:nth-child(3) {
                      background-color: #76ff03;
                    }
                  }
                }
              }
            }
            .description {
              @include center();
              align-items: flex-start;
              flex-direction: column;
              width: 50%;
              height: 100%;
              .techs {
                margin-top: 50px;
                i {
                  font-size: 55px;
                  margin-right: 15px;
                }
                img {
                  height: 55px;
                  width: 55px;
                  margin-right: 15px;
                }
                img.express {
                  width: 120px;
                }
              }
              p,
              h4 {
                font-weight: 500;
                font-size: 1.5em;
                @media screen and (max-width: 1440px) {
                  font-size: 1.2em;
                }
              }
            }
          }
        }
      }
      .Contact {
        header {
          animation: aboutHeader 1s ease both;
          width: 100%;
          height: 100vh;
          @include center();
          p {
            font-weight: 700;
            font-size: 4em;
            user-select: none;
          }
        }
        section {
          width: 100%;
          height: 100vh;
          padding: 0 150px;
          h3 {
            font-weight: 500;
            font-size: 2.5em;
          }
          p {
            font-weight: 300;
            font-size: 1.5em;
            a {
              text-decoration: none;
              font-size: inherit;
              font-weight: inherit;
              color: $primary;
              transition: all 0.3s;
              cursor: pointer;
              &:hover {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}
.nav-list {
  position: fixed;
  right: 50px;
  z-index: 999;
  list-style-type: none;
  animation: navList 1s ease both;
  top: 0;
  right: 50px;
  @media screen and (max-width: 1440px) {
    backdrop-filter: blur(0px);
  }
  li {
    transition: all 0.4s;
    &:hover {
      transform: translateX(25px);
    }
    a {
      color: rgba(255, 255, 255, 0.199);
      font-weight: 700;
      font-size: 2em;
      cursor: pointer;
      transition: all 0.4s;
      @media screen and (max-width: 1440px) {
        font-size: 1.3em;
      }
      &:hover {
        color: white;
      }
    }
  }
}
.nav-btn {
  position: fixed;
  z-index: 1000;
  display: none;
  border: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.397);
  backdrop-filter: blur(5px);
  height: 50px;
  width: 50px;
  user-select: none;
  left: 20px;
  i {
    color: white;
    user-select: none;
  }
}
.clicked {
  background-color: white !important;
  i {
    color: black !important;
  }
}
.show {
  display: block !important;
}
.opacity {
  opacity: 0.4;
}
//Keyfarmes//
@keyframes navList {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes wave {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes moveLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveLeftWork {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes moveUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes fadeAway {
  0% {
    opacity: 1;
    transform: translateY(100%);
  }
  50% {
    transform: translateY(0%);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@keyframes aboutHeader {
  from {
    transform: translateY(100%);
    opacity: 0.2;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes link {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0%);
  }
}
////
Link,
a {
  text-decoration: none;
}
.blob-mobile {
  opacity: 0.2 !important;
  transition: all 0.5s;
}
.top {
  z-index: 1000 !important;
}
.mobile-about span:after {
  content: "About" !important;
  transition: all 0.5s;
  margin-left: 30px;
}
.mobile-work span:after {
  content: "Work" !important;
  transition: all 0.7s;
  margin-left: 30px;
}
.mobile-contact span:after {
  content: "Contact" !important;
  transition: all 1s;
  margin-left: 30px;
}
//media queries
@media (max-width: 1024px) {
  #root {
    .App {
      // padding: 0 120px;
      .container {
        .About {
          section {
            padding: 0 70px;
          }
        }
        .Contact {
          section {
            padding: 0 70px;
          }
        }
        .Work {
          section {
            .name {
              h2 {
                font-size: 2em;
              }
            }
            .showcase {
              .image {
                img {
                  height: 200px;
                  width: 360px;
                }
              }
              .description {
                .techs {
                  i {
                    font-size: 40px;
                    margin-right: 10px;
                  }
                  img {
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                  }
                  img.express {
                    width: 90px;
                  }
                }
                p,
                h4 {
                  font-size: 1em;
                }
              }
            }
          }
        }
        .work-page-container {
          > div {
            .projects-list {
              li {
                height: 150px;
                &:hover {
                  height: auto;
                  .project-details {
                    flex-direction: column;
                    height: auto;
                  }
                }
                .project-details {
                  padding: 0 20px;
                  .image-container {
                    img {
                    }
                  }
                  .details {
                    align-items: center;
                    justify-content: center;
                    p {
                      font-size: 18px;
                      text-align: center;
                    }
                    .techs {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;
                    }
                  }
                }
                button {
                  height: 40px;
                  font-size: 25px;
                  border-radius: 20px;
                  svg {
                    height: 30px;
                  }
                }
              }
            }
            .timeline {
              padding: 50px 5vw;
              P {
                font-size: 23px;
              }
              .timeline-container {
                ul {
                  li {
                    div {
                      font-size: 18px;
                      span {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #root {
    .App {
      padding: 0;
      .container {
        .Home {
          .mobile {
            visibility: visible;
          }
          .imageConatiner {
            @include center();
          }
        }
        .Work {
          section {
            .name {
              h2 {
                font-size: 2em;
              }
            }
            .showcase {
              .image {
                img {
                  height: 150px;
                  width: 270px;
                }
              }
              .description {
                .techs {
                  i {
                    font-size: 25px;
                    margin-right: 8px;
                  }
                  img {
                    height: 25px;
                    width: 25px;
                    margin-right: 8px;
                  }
                  img.express {
                    width: 60px;
                  }
                }
                p,
                h4 {
                  font-size: 1.1em;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #root {
    .App {
      .container {
        .Home {
          height: 100vh;
          overflow: hidden;
          .welcome {
            font-size: 3em;
          }
          .imageContainer {
            .blob {
              width: 300px;
              height: 300px;
            }
            .photo {
              width: 100px;
              height: 100px;
            }
          }
          .textContainer {
            span {
              font-weight: 700;
              font-size: 3em;
              transition: all 0.5s;
            }
          }
        }
        .About {
          header {
            p {
              font-size: 2em;
            }
          }
          section {
            padding: 0 15px;
            h3 {
              font-size: 1.5em;
            }
            p {
              font-size: 1em;
            }
          }
        }
        .Contact {
          header {
            p {
              font-size: 2em;
            }
          }
          section {
            padding: 0 15px;
            h3 {
              font-size: 1.5em;
            }
            p {
              font-size: 1em;
            }
          }
        }
        .Work {
          padding: 50px 0;
          section {
            //height: 130vh;
            margin-bottom: 50px;
            //padding: 0;
            svg {
              width: 35px;
            }
            .link {
              bottom: -40px;
              font-size: 15px;
            }
            .name {
              height: 20%;
              margin: 0;
              justify-content: center;
              margin-bottom: 20px;
              h2 {
                font-size: 1.3em;
              }
            }
            .showcase {
              height: 80%;
              flex-direction: column;
              .image {
                width: 100%;
                margin-bottom: 30px;
                img {
                  height: 150px;
                  width: 270px;
                }
              }
              .description {
                width: 100%;
                padding: 0 20px;
                .techs {
                  i {
                    font-size: 40px;
                    margin-right: 10px;
                  }
                  img {
                    height: 40px;
                    width: 40px;
                    margin-right: 10px;
                  }
                  img.express {
                    width: 90px;
                  }
                }
                p,
                h4 {
                  font-size: 1.3em;
                  font-size: 1em;
                }
              }
            }
          }
        }
        .work-page-container {
          flex-direction: column-reverse;
          height: auto;
          > div {
            width: 100%;
            height: auto;
            .projects-list {
              padding: 0 5px;
              li {
                height: 150px;
                &:hover {
                  height: auto;
                  .project-details {
                    flex-direction: column;
                    height: auto;
                  }
                }
                .project-details {
                  padding: 0 20px;
                  .image-container {
                    img {
                      height: 150px;
                    }
                  }
                  .details {
                    align-items: center;
                    justify-content: center;
                    p {
                      font-size: 18px;
                      text-align: center;
                    }
                    .techs {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;
                    }
                  }
                }
                button {
                  height: 40px;
                  font-size: 25px;
                  border-radius: 20px;
                  svg {
                    height: 30px;
                  }
                }
              }
            }
            .timeline {
              padding: 50px 10px;
              P {
                font-size: 18px;
              }
              .timeline-container {
                ul {
                  li {
                    div {
                      padding-inline: 5px;
                      font-size: 15px;
                      span {
                        font-size: 16px;
                      }
                    }
                    &:not(:last-child) > :nth-child(2) {
                      height: 500px;
                    }
                  }
                }
              }
            }
            &:last-child {
              border-left: none;
            }
          }
        }
      }
    }
  }
  .nav-list {
    display: none;
    li {
      a {
        color: white;
      }
    }
  }
  .nav-btn {
    display: block;
  }
}

.work-page-container {
  height: 100vh;
  padding: 0 !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  > div {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    transition: flex 1s ease-in-out, height 0.5s linear;
    &.active {
      flex: 5;
    }
    .projects-list {
      width: 100%;
      height: 100%;
      list-style-type: none;
      overflow-y: scroll;
      animation: delayShow 0.8s ease-in-out;
      padding-right: 5px;
      &::-webkit-scrollbar {
        all: unset;
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #1976d27e;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #1976d2;
      }
      &::-webkit-scrollbar-button {
        width: 0px;
      }
      li {
        border-radius: 5px;
        width: 100%;
        background-color: $primary;
        height: 200px;
        transition: height 0.5s ease, background-color 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        overflow: hidden;
        padding-block: 50px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:hover {
          height: 600px;
          background-color: transparent;
          .project-details {
            opacity: 1;
            margin-block: 30px;
            height: 250px;
          }
          button {
            animation: fadeIn 0.8s ease-in-out;
            opacity: 1;
          }
        }
        h2 {
          font-weight: 500;
          font-size: 4em;
          @media screen and (max-width: 1440px) {
            font-size: 3em;
          }
          @media screen and (max-width: 1024px) {
            font-size: 1.5em;
          }
        }
        .project-details {
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.3s ease;
          width: 100%;
          padding: 0 50px;
          gap: 50px;
          overflow: hidden;
          .image-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 200px;
              border-radius: 20px;
            }
          }
          .details {
            flex: 2;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            p {
              margin-bottom: 20px;
              font-weight: 300;
              font-size: 20px;
            }
            .techs {
              p {
                margin-bottom: 0px;
                font-weight: 700;
              }
            }
          }
        }
        button {
          opacity: 0;
          background-color: rgba(255, 255, 255, 0.582);
          // width: 200px;
          height: 50px;
          border: none;
          border-radius: 50px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 30px;
          letter-spacing: 5px;
          padding-inline: 10px;
          transition: background-color 0.3s ease;
          &:hover {
            background-color: white;
          }
          &:disabled {
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.267);
            color: rgba(255, 255, 255, 0.267);
            cursor: default;
          }
        }
      }
    }
    .timeline {
      animation: textFlash 1s ease-in-out;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: 100px 15%;
      gap: 50px;
      overflow-y: scroll;
      p {
        text-align: center;
        font-size: 30px;
      }
      .timeline-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        ul {
          li {
            div {
              font-family: "Poppins", sans-serif;
              font-size: 20px;
              font-weight: 300;
              span {
                font-weight: 500;
                font-size: 22px;
              }
            }
            &:not(:last-child) > :nth-child(2) {
              height: 300px;
            }
          }
        }
      }
    }

    .thumbnail {
      height: 100%;
      width: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:hover {
        .icon {
          height: 60%;
          width: 60%;
        }
      }
      span {
        font-weight: 300;
        font-size: 60px;
        letter-spacing: 8px;
        z-index: 2;
      }
      .icon {
        position: absolute;
        height: 40%;
        width: 40%;
        opacity: 0.5;
        color: $primary;
        transition: height 0.3s ease, width 0.3s ease;
      }
      &.vertical {
        span {
          writing-mode: vertical-rl;
          animation: textFlash 1s ease-in-out;
        }
      }
    }
    &:last-child {
      border-left: 3px solid rgba(255, 255, 255, 0.295);
    }
  }
}

@keyframes fadeIn {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes delayShow {
  0%,
  90% {
    display: none;
  }
  100% {
    display: block;
  }
}
@keyframes textFlash {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
